import demoLogo from "./logo/logo.png";
import summerbaylogo from "../assets/img/logo/sbay-app-logo.png"
import { generateAppConfig } from "../config";

let  logo = demoLogo

let applicationName = "Travoxios Digital Intelligence Platform (TDIP)"

let siteName = "https://travoxis.com"

let FooterName = "Travoxios (1165017-V)"

if(process.env.REACT_APP_PRODUCTION_MODE !== "STAGE"){
    applicationName = "Summer Cruise Digital Intelligence Platform (SCDIP)"
    logo = summerbaylogo
    FooterName = " Travoxis Technology SDN BHD"
}

const getBookingDetailsUrl = (reservationId, isHref, isUnApproved) => {
    let bookingDetailsUrl = `${generateAppConfig().adminRepoLocation}/booking/details/${reservationId}`
    let target = "_self"
    if(process.env.REACT_APP_BOOKING_DETAILS_PAGE === "NEW"){
        const userInfo = JSON.parse(localStorage.getItem("accessToken"))
        bookingDetailsUrl = `${generateAppConfig().bookingAppRepoLocation}/bookingdetails/${reservationId}?userId=${userInfo.userId}&firstName=${userInfo.firstName}&lastName=${userInfo.lastName}&isUnApproved=${isUnApproved ? isUnApproved : false}&token=${userInfo.accessToken}&bookingDetailPage=true`
        target = "_blank"
    }
    if(isHref){
        return bookingDetailsUrl
    }
    window.open(bookingDetailsUrl, target)
}

export {
    logo,
    applicationName,
    siteName,
    FooterName,
    getBookingDetailsUrl
}
