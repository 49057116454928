export const generateAppConfig = () => {
  let exportResult = {
    appRepoLocation: "https://sg-app.summercruise.com.my",
    backendUrl: "http://localhost:3100",
    jsReportUrl: "http://localhost:5488",
    travelAgentBackendUrl: "http://localhost:3000",
    adminRepoLocation: "http://localhost:3000",
    bookingAppRepoLocation: "http://localhost:3001",
  };
  if (process.env.REACT_APP_PRODUCTION_MODE === "TEST") {
    exportResult.appRepoLocation = "https://sg-app.summercruise.com.my";
    exportResult.backendUrl = "https://sg-api.summercruise.com.my/admin";
    exportResult.jsReportUrl = "https://sg-report.summercruise.com.my";
    exportResult.travelAgentBackendUrl =
      "https://sg-api.summercruise.com.my/business";
    exportResult.adminRepoLocation =
      "https://sg-admin.summercruise.com.my";
    exportResult.bookingAppRepoLocation = "https://sg-booking.summercruise.com.my";
  }
  if (process.env.REACT_APP_PRODUCTION_MODE === "STAGE") {
    exportResult.appRepoLocation = "https://app.travoxis.com";
    exportResult.backendUrl = "https://admin-api.travoxis.com";
    exportResult.jsReportUrl = "https://sg-report.summercruise.com.my";
    exportResult.travelAgentBackendUrl = "https://business-api.travoxis.com";
    exportResult.adminRepoLocation = "https://admin.travoxis.com";
    exportResult.bookingAppRepoLocation = "https://booking.travoxis.com";
  }
  if (process.env.REACT_APP_PRODUCTION_MODE === "PROD") {
    exportResult.appRepoLocation = "https://app.summercruise.com.my";
    exportResult.backendUrl = "https://api.summercruise.com.my/admin";
    exportResult.jsReportUrl = "https://report.summercruise.com.my";
    exportResult.travelAgentBackendUrl =
      "https://api.summercruise.com.my/business";
    exportResult.adminRepoLocation = "https://admin.summercruise.com.my";
    exportResult.bookingAppRepoLocation = "https://booking.summercruise.com.my";
  }
  return exportResult;
};
